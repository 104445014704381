<template>
    <div
        v-show="loading"
        class="preloader"
    >
        <v-progress-linear
            indeterminate
        />
    </div>
</template>

<script>
export default {
    name: 'QuestionsProcessPreloader',
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang=scss scoped>
.preloader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25%;
    background-color: #fff;
}
</style>
