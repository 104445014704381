<template>
    <v-card flat class="questions-process questions-process--start">
        <v-card-title class="d-flex text-truncate text-base font-weight-bold py-6">
            <span class="text-truncate">{{ title }}</span>
        </v-card-title>
        <v-divider/>
        <v-card-text
            class="text-center px-4 py-8 d-flex align-center justify-center"
            v-bind="vCardTextBindings"
        >
            <div>
                <p v-if="author">
                    <strong>{{ $trans('Author') }}</strong>:
                    <span>{{ author.member | memberFullName }}</span>
                </p>
                <p v-if="duration">
                    <strong>{{ $trans('Duration') }}</strong>:
                    <span>{{ duration }}</span>
                </p>
                <p>
                    <strong>{{ $trans('Questions count') }}</strong>:
                    <span>{{ questionsCount }}</span>
                </p>
                <p class="text-2xl">
                    <v-chip
                        label
                        large
                        color="primary"
                        class="text-2xl v-chip-light-bg primary--text"
                    >
                        <strong>{{ scores }}</strong>&nbsp;
                        {{ $transChoice('scores', scores % 20) }}
                    </v-chip>
                </p>
            </div>
        </v-card-text>
        <v-divider/>
        <v-card-actions class="">
            <v-btn
                color="secondary"
                text
                @click="$emit('close')"
            >
                {{ $trans('Cancel') }}
            </v-btn>
            <v-spacer/>
            <v-btn
                depressed
                :disabled="questionsCount === 0"
                color="success"
                @click="$emit('start')"
            >
                {{ $trans('Start') }}
            </v-btn>
        </v-card-actions>
        <questions-process-preloader
            :loading="loading"
        />
    </v-card>
</template>

<script>
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'
import QuestionsProcessPreloader from '@apps/questions/components/QuestionsProcess/QuestionsProcessPreloader'
import questionsProcessBaseMixin from '@apps/questions/components/QuestionsProcess/mixins/questionsProcessBaseMixin'
import hasMemberFullNameFilterMixin from '@/components/Member/mixins/hasMemberFullNameFilterMixin'

export default {
    name: 'QuestionsProcessStart',
    components: { QuestionsProcessPreloader },
    mixins: [
        mobileBreakpointChecker,
        questionsProcessBaseMixin,
        hasMemberFullNameFilterMixin
    ],
    props: {
        item: Object,
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        title() {
            if(!this.item) {
                return null
            }

            return this.item.title
        },
        author() {
            if(!this.item) {
                return null
            }

            return this.item.author
        },
        duration() {
            if(!this.item) {
                return null
            }

            const duration = this.item.options.duration

            if(!duration) {
                return null
            }

            return `${duration} ${this.$transChoice('minutes', duration % 20)}.`
        },
        questionsCount() {
            if(!this.item) {
                return 0
            }

            return this.item.sets.length
        },
        scores() {
            if(!this.item) {
                return 0
            }

            let totalScores = 0

            this.item.sets.forEach(o => {
                totalScores += Number(o.score)
            })

            return totalScores
        }
    },
}
</script>
